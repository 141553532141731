import React from 'react'
import { useMemberQuery, useUserQuery } from '../../redux/services/pthApi'
import { RootState } from '../../redux/store'
import { VStack, HStack, Spinner, Center } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import { useHistory, useParams } from 'react-router-dom'
import { MdArrowBack } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { getCurrency, addDecimalsToMoney } from '../../utils/Helpers'
import MapTransaction from '../../components/atoms/MapTransaction'
import { colors } from '../../utils/Colors'
import { LOCALE } from '../../constants'

interface MemberScreenProps {}

const MemberScreen: React.FC<MemberScreenProps> = () => {
  const session = useSelector((state: RootState) => state.session)
  const { memberID } = useParams<{ memberID: string }>()
  const member = useMemberQuery(Number(memberID))
  const user = useUserQuery(Number(session.id), { skip: !session.id })
  const history = useHistory()
  const userData = user.data

  return (
    <VStack w="full" h="100vh" display="flex">
      {member.error ? (
        <Center w="100%" h="100%">
          <CText.Description color="white">
            Oh no, there was an error
          </CText.Description>
        </Center>
      ) : member.isLoading ? (
        <>
          <Center w="100%" h="100%">
            <VStack>
              <Spinner size="md" color={colors.white} />
              <CText.Description color={colors.white}>
                Please wait
              </CText.Description>
            </VStack>
          </Center>
        </>
      ) : member.data && userData ? (
        <>
          <VStack w="100%" h="100%" maxW="500px">
            <HStack
              w="90%"
              display="flex"
              justifyContent="flex-start"
              paddingTop="30px"
            >
              <VStack alignItems="flex-start" spacing={0}>
                <MdArrowBack
                  onClick={() => history.goBack()}
                  color={colors.white}
                  size="30px"
                  cursor="pointer"
                />
                <CText.HeaderSM
                  color={colors.white}
                  fontSize="23px"
                  paddingTop="25px"
                >
                  {member.data.name}
                </CText.HeaderSM>
                <CText.Description
                  color={colors.lightgrey}
                  paddingBottom="30px"
                >
                  {member.data.fines - member.data.deposits < 0
                    ? `Paid ${
                        addDecimalsToMoney(
                          (member.data.fines - member.data.deposits) * -1
                        ) +
                        ' ' +
                        getCurrency(LOCALE, userData.currency)
                      } too much`
                    : member.data.fines - member.data.deposits === 0
                    ? 'Owes nothing'
                    : `Owes ${
                        addDecimalsToMoney(
                          member.data.fines - member.data.deposits
                        ) +
                        ' ' +
                        getCurrency(LOCALE, userData.currency)
                      }`}
                </CText.Description>
              </VStack>
            </HStack>
            {member.data.transactions.map(transaction => {
              return (
                <MapTransaction
                  key={transaction.id}
                  transaction={transaction}
                  countryCode={LOCALE}
                  currency={userData.currency}
                />
              )
            })}
          </VStack>
        </>
      ) : null}
    </VStack>
  )
}

export default MemberScreen
