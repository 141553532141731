import Navigator from './Navigator'
import './reset.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ChakraProvider } from '@chakra-ui/react'
import chakraTheme from './chakraTheme'

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={chakraTheme} resetCSS={true}>
        <Navigator />
      </ChakraProvider>
    </Provider>
  )
}

export default App
