import {
  useMembersQuery,
  useUserQuery,
  useFineTypesQuery,
} from '../../redux/services/pthApi'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { VStack, HStack, Image, Spinner, Center } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import {
  getCurrency,
  getTeamFines,
  getTeamDeposits,
  addDecimalsToMoney,
} from '../../utils/Helpers'
import MapMember from '../../components/atoms/MapMember'
import { colors } from '../../utils/Colors'
import MapFineType from '../../components/atoms/MapFineType'
import CategoryButton from '../../components/molecules/CategoryButton'
import { LOCALE } from '../../constants'

interface HomeScreenProps {}

const HomeScreen: React.FC<HomeScreenProps> = () => {
  const session = useSelector((state: RootState) => state.session)
  const user = useUserQuery(session.id, { skip: !session.token })
  const members = useMembersQuery(null, { skip: !session.token })
  const fines = useFineTypesQuery(null, { skip: !session.token })
  const loading = user.isLoading && members.isLoading && fines.isLoading
  const error = user.error && members.error && fines.error
  const userData = user.data
  let [fineOrMembers, setFineOrMembers] = useState(true)

  useEffect(() => {
    if (userData?.teamName) {
      document.title = `PTH - ${userData.teamName}`
    }
  }, [userData])

  return (
    <VStack w="full" h="100vh" display="flex" spacing="50px">
      {error ? (
        <Center w="100%" h="100%">
          <CText.Description color="white">
            Oh no, there was an error {members.error}
          </CText.Description>
        </Center>
      ) : loading ? (
        <>
          <Center w="100%" h="100%">
            <VStack>
              <Spinner size="md" color={colors.white} />
              <CText.Description color="white">Please wait</CText.Description>
            </VStack>
          </Center>
        </>
      ) : members.data && userData && fines.data ? (
        <>
          <HStack paddingTop="40px">
            {userData.teamLogo ? (
              <Image
                src={userData.teamLogo}
                borderRadius="full"
                boxSize="55px"
                alt="Team Logo"
              />
            ) : null}
            <CText.Description color={colors.white} fontSize="13px">
              {userData.teamName}
            </CText.Description>
          </HStack>
          <VStack>
            <CText.Description color={colors.white} fontSize="12px">
              Balance
            </CText.Description>
            <CText.Description color={colors.white} fontSize="30px">
              {addDecimalsToMoney(getTeamFines(members.data)) +
                ' ' +
                getCurrency(LOCALE, userData.currency)}
            </CText.Description>
            <CText.Description color={colors.lightgrey}>
              {addDecimalsToMoney(getTeamDeposits(members.data)) +
                ' ' +
                getCurrency(LOCALE, userData.currency) +
                ' '}
              deposited
            </CText.Description>
          </VStack>

          <VStack w="full" maxW="500px" spacing="15px" paddingBottom="100px">
            <HStack marginBottom="20px">
              <CategoryButton
                title="Members"
                category={fineOrMembers}
                onClick={() => setFineOrMembers(true)}
              />
              <CategoryButton
                title="Fine types"
                category={!fineOrMembers}
                onClick={() => setFineOrMembers(false)}
              />
            </HStack>

            {fineOrMembers ? (
              <>
                {members.data.map((member, i) => {
                  return (
                    <MapMember
                      member={member}
                      index={i}
                      countryCode={LOCALE}
                      currency={userData.currency}
                      key={member.id}
                    />
                  )
                })}
              </>
            ) : (
              <>
                {fines.data.map(fine => {
                  return (
                    <MapFineType
                      fine={fine}
                      countryCode={LOCALE}
                      currency={userData.currency}
                      key={fine.id}
                    />
                  )
                })}
              </>
            )}
          </VStack>
        </>
      ) : null}
    </VStack>
  )
}

export default HomeScreen
