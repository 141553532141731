import { Fine } from '../../redux/services/pthApi'
import { HStack, Box } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import { getCurrency, addDecimalsToMoney } from '../../utils/Helpers'
import { colors } from '../../utils/Colors'

interface Props {
  fine: Fine
  countryCode: string
  currency: string
}

export default function MapFineType({ fine, countryCode, currency }: Props) {
  return (
    <HStack
      w="90%"
      display="flex"
      justifyContent="space-between"
      paddingTop="10px"
    >
      <CText.Description fontSize="13px" color={colors.white}>{fine.name}</CText.Description>

      <Box
        bg={colors.fadedRed}
        paddingTop="4px"
        paddingBottom="2px"
        paddingLeft="7px"
        paddingRight="6px"
        borderRadius="md"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CText.Description fontSize="12px" color={colors.red} fontFamily="MarkProMedium">
          {addDecimalsToMoney(fine.amount) +
            ' ' +
            getCurrency(countryCode, currency)}
        </CText.Description>
      </Box>
    </HStack>
  )
}
