import { extendTheme } from '@chakra-ui/react'
// import { createBreakpoints } from '@chakra-ui/theme-tools'

export default extendTheme({
  colors: {},
  config: {
    cssVarPrefix: 'chakra',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    body: `'MarkProRegular'`,
  }
})
