import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import AuthScreen from './screens/Login/AuthScreen'
import PageNotFoundScreen from './screens/PageNotFound'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/team">
          <AppNavigator />
        </Route>
        <Route exact path="/:guid">
          {/* Warning: LoginScreen (and everything not under AppNavigator) is
          not checked by AppAuthorizationChecker, meaning that the SessionState
          is not type-safe */}
          <AuthScreen />
        </Route>
        <Route path="*">
          <PageNotFoundScreen />
        </Route>
      </Switch>
    </Router>
  )
}
