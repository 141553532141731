import {
  useMembersQuery,
  useMemberTokenQuery,
} from '../../redux/services/pthApi'
import React, { useEffect } from 'react'
import { RootState } from '../../redux/store'
import { Center, VStack, Spinner } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { startSession } from '../../redux/slices/sessionSlice'
import { colors } from '../../utils/Colors'

interface AuthScreenProps {}

const AuthScreen: React.FC<AuthScreenProps> = () => {
  const { guid } = useParams<{ guid: string }>()
  const memberToken = useMemberTokenQuery(guid)
  const dispatch = useDispatch()
  const history = useHistory()
  const session = useSelector((state: RootState) => state.session)
  const members = useMembersQuery(null, { skip: !session.token })
  const memberData = members.data

  useEffect(() => {
    if (memberToken.data) {
      dispatch(
        startSession({
          id: memberToken.data.userId,
          token: memberToken.data.token,
        })
      )
    }
  }, [memberToken.data])

  useEffect(() => {
    if (memberData) {
      history.push('/team')
    }
  }, [memberData])

  return (
    <>
      {members.error || memberToken.error ? (
        <Center w="100%" h="100%">
          <CText.Description color="white">
            Oh no, there was an error
          </CText.Description>
        </Center>
      ) : members.isLoading || memberToken.isLoading ? (
        <>
          <Center w="100%" h="100%">
            <VStack>
              <Spinner size="md" color={colors.white} />
              <CText.Description color="white">Please wait</CText.Description>
            </VStack>
          </Center>
        </>
      ) : null}
    </>
  )
}

export default AuthScreen
