import React from 'react'
import { Center } from '@chakra-ui/react'
import * as CText from '../components/CText'

interface PageNotFoundScreenProps {}

const PageNotFoundScreen: React.FC<PageNotFoundScreenProps> = () => {
  return (
    <Center w="100%" h="100%">
      <CText.Description color="white">Page not found</CText.Description>
    </Center>
  )
}

export default PageNotFoundScreen
