import * as React from 'react'
import { Text, TextProps, Skeleton } from '@chakra-ui/react'

interface CustomProps {
  sampleText?: string
  isLoading?: boolean
  isActive?: boolean
}

type StyledSkeletonTextProps = TextProps & CustomProps

export const StyledSkeletonText = (props: StyledSkeletonTextProps) => {
  if (props.sampleText && props.isLoading) {
    return (
      <Skeleton
        display="inline-block"
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        borderRadius="6px"
      >
        {props.sampleText}
      </Skeleton>
    )
  }
  return <Text {...props} />
}

export const HeaderSM = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    fontWeight="bold"
    fontSize={{ base: '14px', md: '16px' }}
    mt="0px !important"
    color="#000"
    {...props}
  />
)

export const Description = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="#9E9DAC"
    fontSize={{ base: '14px', md: '14px' }}
    mb="0px"
    fontWeight="normal"
    {...props}
  />
)

export const Stat = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    fontWeight="semi"
    fontSize={{ base: '16px', md: '18px' }}
    {...props}
  />
)

export const Regular = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText color="#000" fontSize={{ md: '13px' }} {...props} />
)

export const InputHeader = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="#000"
    fontSize="15px"
    fontWeight="600"
    {...props}
  />
)

export const Warning = (props: StyledSkeletonTextProps) => (
  <StyledSkeletonText
    color="#CC4242"
    fontSize={{ base: '14px', lg: '13px' }}
    fontWeight="500"
    {...props}
  />
)