import type { Member } from "../redux/services/pthApi"


export const getCurrency = (locale: string, currency: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumSignificantDigits: 5,
    currencyDisplay: 'symbol',
  })
    .format(0)
    .toString()
    .replace(/[0-9]/g, '')
    .trim()
}

export const getTeamFines = (members: Member[] | undefined) => {
  if (members === undefined || members.length === 0) return 0
  let sum = 0
  sum = members.reduce((prev, curr) => prev + curr.fines, 0)

  return sum
}

export const getTeamDeposits = (members: Member[] | undefined) => {
  if (members === undefined || members.length === 0) return 0
  let sum = 0
  sum = members.reduce((prev, curr) => prev + curr.deposits, 0)

  return sum
}

export const addDecimalsToMoney = (money: string | number) => {
  if(typeof money === 'number') money = money.toString()
  if(money.length < 4) return money
  let count = 0
  let newMoney = ""
  for (let i = money.length - 1; i >= 0; i--) {
    if(count % 3 === 0 && count !== 0) newMoney += "."
    count++
    newMoney += money[i]
  }
  return newMoney.split("").reverse().join("");
}