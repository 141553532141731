import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import AppAuthorizationChecker from './containers/AppAuthorizationChecker'
import HomeScreen from './screens/Home/HomeScreen'
import MemberScreen from './screens/Home/MemberScreen'
import PageNotFoundScreen from './screens/PageNotFound'

interface AppNavigatorProps {}

const AppNavigator: React.FC<AppNavigatorProps> = () => {
  let { path } = useRouteMatch()

  return (
    <AppAuthorizationChecker>
      <Switch>
        <Route path={`${path}/members/:memberID`}>
          <MemberScreen />
        </Route>
        <Route path={`${path}/`}>
          <HomeScreen />
        </Route>

        <Route path="*">
          <PageNotFoundScreen />
        </Route>
      </Switch>
    </AppAuthorizationChecker>
  )
}

export default AppNavigator
