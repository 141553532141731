import { Member } from '../../redux/services/pthApi'
import { VStack, HStack, Box } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import { useHistory } from 'react-router-dom'
import { getCurrency, addDecimalsToMoney } from '../../utils/Helpers'
import { colors } from '../../utils/Colors'

interface Props {
  member: Member
  index: number
  countryCode: string
  currency: string
}

export default function MapMember({
  member,
  index,
  countryCode,
  currency,
}: Props) {
  const history = useHistory()
  let bgColorBasedOnIndex = colors.black
  let txtColorBasedOnIndex = colors.black
  if (index === 0) bgColorBasedOnIndex = colors.gold
  if (index === 1) bgColorBasedOnIndex = colors.silver
  if (index === 2) bgColorBasedOnIndex = colors.bronze
  if (index > 2) bgColorBasedOnIndex = colors.darkgrey
  if (index > 2) txtColorBasedOnIndex = colors.white
  return (
    <HStack
      w="90%"
      display="flex"
      justifyContent="space-between"
      cursor="pointer"
      onClick={() => {
        history.push(`/team/members/${member.id}`, { countryCode, currency })}
      }
    >
      <div>
        <HStack spacing="15px">
          <Box
            bg={bgColorBasedOnIndex}
            w="40px"
            h="40px"
            borderRadius="27%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CText.Description
              color={txtColorBasedOnIndex}
              fontWeight="bold"
              fontSize="13px"
            >
              #{index + 1}
            </CText.Description>
          </Box>
          <CText.Description color={colors.white}>
            {member.name}
          </CText.Description>
        </HStack>
      </div>
      <VStack spacing="0px">
        <CText.Description fontSize="12px" color={colors.red}>
          {addDecimalsToMoney(member.fines) +
            ' ' +
            getCurrency(countryCode, currency)}
        </CText.Description>
        <CText.Description fontSize="12px" color={colors.green}>
          {addDecimalsToMoney(member.deposits) +
            ' ' +
            getCurrency(countryCode, currency)}
        </CText.Description>
      </VStack>
    </HStack>
  )
}
