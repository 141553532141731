import { Transaction } from '../../redux/services/pthApi'
import { VStack, HStack } from '@chakra-ui/react'
import * as CText from '../../components/CText'
import { getCurrency, addDecimalsToMoney } from '../../utils/Helpers'
import moment from 'moment'
import { colors } from '../../utils/Colors'

interface Props {
  transaction: Transaction
  countryCode: string
  currency: string
}

export default function MapMember({
  transaction,
  countryCode,
  currency,
}: Props) {
  return (
    <HStack
      w="90%"
      display="flex"
      justifyContent="space-between"
      paddingBottom="8px"
    >
      <CText.Description color={colors.white}>
        {transaction.name}
      </CText.Description>
      <VStack spacing={0} alignItems="flex-end" width="50%">
        <CText.Description
          color={
            transaction.transactionType === 'deposit'
              ? colors.green
              : colors.red
          }
        >
          {addDecimalsToMoney(transaction.amount) +
            ' ' +
            getCurrency(countryCode, currency)}
        </CText.Description>
        <CText.Description color={colors.lightgrey}>
          {moment(transaction.date).format('D MMM YYYY')}
        </CText.Description>
      </VStack>
    </HStack>
  )
}
