const colors = {
  white: '#FFFFFF',
  black: "#000000",
  red: '#C1574A',
  fadedRed: '#36272C',
  green: '#41C7AE',
  gold: '#FFC400',
  silver: '#D1D1D1',
  bronze: '#C18107',
  darkgrey: '#1E202B',
  lightgrey: '#8B96B7',
}

export {colors}
