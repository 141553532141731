import { Box } from '@chakra-ui/react'
import * as CText from '../../components/CText'

import { colors } from '../../utils/Colors'

interface Props {
  title: string
  category: boolean
  onClick: () => void
}

export default function CategoryButton({ title, category, onClick }: Props) {
  return (
    <Box
      transition="0.3s all"
      bg={!category ? colors.darkgrey : colors.white}
      h="40px"
      w="120px"
      borderRadius="25"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={onClick}
      letterSpacing="0px"
      
    >
      <CText.HeaderSM
        color={category ? colors.black : colors.white}
        fontSize="12px"
        fontFamily="MarkProMedium"
      >
        {title}
      </CText.HeaderSM>
    </Box>
  )
}
