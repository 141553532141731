// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../constants'
import { RootState } from '../store'

export type User = {
  id: number
  email: string
  currency: string
  teamLogo: string
  teamName: string
  phoneNumber: string
  countryCode: string
  mobilePayActive: boolean
  vippsActive: boolean
  swishActive: boolean
  totalWithdraw: number
}

export type Member = {
  id: number
  name: string
  amount: number
  fines: number
  deposits: number
  transactions: Transaction[]
}

export type Transaction = {
  amount: number
  date: string
  deposit: unknown
  fine: unknown
  id: number
  member: unknown
  memberId: unknown
  name: string
  transactionType: string
}

export type MemberToken = {
  userId: number
  token: string
}

export type Fine = {
  id: number
  name: string
  amount: number
}

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState
    const token = state.session.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

// Define a service using a base URL and expected endpoints
export const pthApi = createApi({
  reducerPath: 'pthApi',
  baseQuery,
  endpoints: builder => ({
    user: builder.query<User, number>({
      query: id => ({
        url: `/User/${id}`,
        method: 'GET',
      }),
    }),
    members: builder.query<Member[], null>({
      query: () => ({
        url: `/Member`,
        method: 'GET',
      }),
      transformResponse: (responseData: { members: Member[] }) => responseData.members.sort((a,b) => b.fines - a.fines),
    }),
    member: builder.query<Member, number>({
      query: id => ({
        url: `/Member/${id}`,
        method: 'GET',
      }),
    }),
    memberToken: builder.query<MemberToken, string>({
      query: guid => ({
        url: `/User/MemberAccessToken/${guid}`,
        method: 'GET',
      }),
    }),
    fineTypes: builder.query<Fine[], null>({
      query: () => ({
        url: `/Fine`,
        method: 'GET',
      }),
      transformResponse: (responseData: { fines: Member[] }) => responseData.fines,
    }),
  }),
})

export const { useUserQuery, useMembersQuery, useMemberQuery, useMemberTokenQuery, useFineTypesQuery } = pthApi
